var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button-group',{staticClass:"mb-1 align-self-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Simpan'),expression:"'Simpan'",modifiers:{"hover":true,"top":true}},{name:"b-toggle",rawName:"v-b-toggle.collapse-filter",modifiers:{"collapse-filter":true}}],attrs:{"variant":"outline-secondary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){return _vm.onSave()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.isLoading)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_vm._e()],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Refresh'),expression:"'Refresh'",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"outline-secondary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){return _vm.onRefresh()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.isLoading)?_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}}):_vm._e()],1)],1)],1)])],1),_c('validation-observer',{ref:"myForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-card',{staticClass:"border-dark",attrs:{"title":"OSS"}},[_c('b-form-group',{attrs:{"label":"OSS User Key","label-for":"oss_user_key"}},[_c('validation-provider',{attrs:{"name":"OSS User Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.oss_user_key),callback:function ($$v) {_vm.$set(_vm.form, "oss_user_key", $$v)},expression:"form.oss_user_key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"OSS Token","label-for":"oss_token"}},[_c('validation-provider',{attrs:{"name":"OSS Token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.oss_token),callback:function ($$v) {_vm.$set(_vm.form, "oss_token", $$v)},expression:"form.oss_token"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-card',{staticClass:"border-dark",attrs:{"title":"SICANTIK"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.sicantik_username),callback:function ($$v) {_vm.$set(_vm.form, "sicantik_username", $$v)},expression:"form.sicantik_username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.sicantik_password),callback:function ($$v) {_vm.$set(_vm.form, "sicantik_password", $$v)},expression:"form.sicantik_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Token","label-for":"token"}},[_c('validation-provider',{attrs:{"name":"Token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.sicantik_token),callback:function ($$v) {_vm.$set(_vm.form, "sicantik_token", $$v)},expression:"form.sicantik_token"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-card',{staticClass:"border-dark",attrs:{"title":"SIMBG"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Cookie Session","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.simbg_username),callback:function ($$v) {_vm.$set(_vm.form, "simbg_username", $$v)},expression:"form.simbg_username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"simbg_password"}},[_c('validation-provider',{attrs:{"name":"Cookie CSRF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.simbg_password),callback:function ($$v) {_vm.$set(_vm.form, "simbg_password", $$v)},expression:"form.simbg_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-card',{staticClass:"border-dark",attrs:{"title":"MPPD"}},[_c('b-form-group',{attrs:{"label":"Cookie Session","label-for":"Cookie Session"}},[_c('validation-provider',{attrs:{"name":"Cookie Session","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : true,"type":"text"},model:{value:(_vm.form.mppd_cookie),callback:function ($$v) {_vm.$set(_vm.form, "mppd_cookie", $$v)},expression:"form.mppd_cookie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }