<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { required } from '@validations'

export default {
   name: 'Pengaturan',
   components: { BCardCode },
   data() {
      return {
         required,
         form: {
            sicantik_username: null,
            sicantik_password: null,
            sicantik_token: null,
            oss_user_key: null,
            oss_token: null,
            simbg_username: null,
            simbg_password: null,
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.Pengaturan.items,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
   },
   mounted() {
      this.loadData()
   },
   methods: {
      ...mapActions({
         getData: 'Pengaturan/getData',
         saveData: 'Pengaturan/saveData',
      }),
      loadData() {
         this.getData().then(res => {
            this.form = res
            console.log(res)
         })
      },
      onSave() {
         this.$refs.myForm.validate().then(success => {
            if (success) {
               this.saveData(this.form).then(() => {
                  this.loadData()
                  this.$bvToast.toast('Data berhasil disimpan.', {
                     title: 'Berhasil', variant: 'success', solid: true,
                  })
               })
            }
         })
      },
      onRefresh() {
         this.loadData()
      },
   },
}
</script>

<template>
   <b-card-code title="Form">
      <b-row>
         <b-col cols="12">
            <div class="d-flex justify-content-end">
               <b-button-group class="mb-1 align-self-end">
                  <b-button v-b-tooltip.hover.top="'Simpan'" v-b-toggle.collapse-filter variant="outline-secondary"
                     size="sm" :disabled="isLoading" @click="onSave()">
                     <b-spinner v-if="isLoading" small />
                     <feather-icon v-if="!isLoading" icon="SaveIcon" />
                  </b-button>
                  <b-button v-b-tooltip.hover.top="'Refresh'" variant="outline-secondary" size="sm"
                     :disabled="isLoading" @click="onRefresh()">
                     <b-spinner v-if="isLoading" small />
                     <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                  </b-button>
               </b-button-group>
            </div>
         </b-col>
      </b-row>
      <validation-observer ref="myForm">
         <b-form>
            <b-row>
               <b-col cols="12" md="4">
                  <b-card class="border-dark" title="OSS">
                     <b-form-group label="OSS User Key" label-for="oss_user_key">
                        <validation-provider #default="{ errors }" name="OSS User Key" rules="required">
                           <b-form-input v-model="form.oss_user_key" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                     <b-form-group label="OSS Token" label-for="oss_token">
                        <validation-provider #default="{ errors }" name="OSS Token" rules="required">
                           <b-form-input v-model="form.oss_token" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                  </b-card>
               </b-col>
               <b-col cols="12" md="4">
                  <b-card class="border-dark" title="SICANTIK">
                     <b-form-group label="Username" label-for="username">
                        <validation-provider #default="{ errors }" name="Username" rules="required">
                           <b-form-input v-model="form.sicantik_username" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                     <b-form-group label="Password" label-for="password">
                        <validation-provider #default="{ errors }" name="Password" rules="required">
                           <b-form-input v-model="form.sicantik_password" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                     <b-form-group label="Token" label-for="token">
                        <validation-provider #default="{ errors }" name="Token" rules="required">
                           <b-form-input v-model="form.sicantik_token" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                  </b-card>
               </b-col>
               <b-col cols="12" md="4">
                  <b-card class="border-dark" title="SIMBG">
                     <b-form-group label="Username" label-for="username">
                        <validation-provider #default="{ errors }" name="Cookie Session" rules="required">
                           <b-form-input v-model="form.simbg_username" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                     <b-form-group label="Password" label-for="simbg_password">
                        <validation-provider #default="{ errors }" name="Cookie CSRF" rules="required">
                           <b-form-input v-model="form.simbg_password" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                  </b-card>
               </b-col>
               <b-col cols="12" md="4">
                  <b-card class="border-dark" title="MPPD">
                     <b-form-group label="Cookie Session" label-for="Cookie Session">
                        <validation-provider #default="{ errors }" name="Cookie Session" rules="required">
                           <b-form-input v-model="form.mppd_cookie" :state="errors.length > 0 ? false : true"
                              type="text" />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                  </b-card>
               </b-col>
            </b-row>
         </b-form>
      </validation-observer>
   </b-card-code>
</template>

<style></style>
